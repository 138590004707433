import React, { memo, useCallback, useEffect, useState } from "react";
import { Linking, Platform, StyleSheet, View } from "react-native";
import Gap from "../Gap";
import { ContentPreview } from "../ContentPreview";
import { Race as RaceReplayAPI } from "../../types/replay";
import { Video as OnDemandVideo, VideoAsset } from "../../types/video";
import Race from "../Card/Race";
import { FreeBet } from "../../types/freeBet";
import { Article } from "../../types/news";
import { resolveValue } from "path-value";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { cleanSlug } from "../../utils/cleanSlug";
import { formatRaceTime } from "../../utils/TimeCalculator";
import { ErrorState, EmptyState, LoadingState } from "../States/states";
import variables from "../../styles/variables";
import { newsThumbnail } from "../Screens/News/utils";
import { UpcomingRace } from "../ComingupBar/ComingupBar";
import { useAtom } from "jotai";
import { articlesForNavigationAtom, globalPlayerSourceAtom } from "../../atoms";
import {
  DEFAULT_OFFER_IMAGE,
  DEFAULT_PREDICTOR_IMAGE,
  DEFAULT_VIDEO_ANALYSIS_IMAGE,
  DEFAULT_REPLAY_IMAGE,
  DEFAULT_RTV_LIVE_IMAGE,
} from "../Image/images/defaultImages";
import { MemberBenefit } from "../../types/page";
import RCorner from "../RCorner/RCorner";
import RTouchableOpacity from "../RLink/RTouchableOpacity";
import { prepareParams } from "../Screens/Results/utils";
import { nativeNavFromSlug } from "../Promotion/Utils";
import { useResponsive } from "../../hooks/useResponsive";
import { RaceReplay } from "../../components/Screens/Watch/Replays/MeetingsDropdown";
import { StackNavigationProp } from "@react-navigation/stack/lib/typescript/src/types";
import { handlePressOnDemand, handlePressReplay } from "../../utils/nav";
import { RootStackParamList } from "../../types/route";
import RFlatList from "../RFlatList/RFlatList";
import { FlatList } from "react-native-gesture-handler";

const itemWidth = 300;
const smallScreenItemWidth = 220;
/*
 This component renders a list of components and injects data from a given endpoint into them via methodMap.
 Importantly it lays out an easy scheme of telling the scrollbar the width of each child element - so pressing
 the left/right arrows will scroll the scrollbar by the width of the child element.

 To add a new type of component e.g UpcomingRaces:
  1) Add a new value to the ContentTypes type, e.g. "UpcomingRaces"
  2) Add a new method to the methodMap and appropriate endpoint. The method should pass (a, b, c, idx) through.
  3) Add a new renderUpcomingRace method which renders a sincular UpcomingRace component AND <Gap> spacer.
*/

type ContentTypes =
  | "ContentPreview"
  | "UpcomingRaces"
  | "Tips"
  | "SimpleStreamDemandVideo"
  | "TipVideo"
  | "OnDemand"
  | "Replays"
  | "FreeBets"
  | "MembersBenefits"
  | "NewsColumnists"
  | "NewsArticles"
  | "NewsColumnistsArticles";

type ContentScrollBarItem<T> = {
  datum: T;
  handleLayout: (e: any) => void;
  idx: number;
  nav?: any;
  category?: string;
  onPress?: () => void;
  scrollRef?: any;
  data?: T[];
  setArticles?: Function;
  isXSmallOnly?: boolean;
};

// prettier-ignore
const renderReplay = memo(({ datum, handleLayout, idx, nav, isXSmallOnly }: ContentScrollBarItem<RaceReplay | RaceReplayAPI>) => {
  const start_time_scheduled = datum?.start_time_scheduled || datum?.startTime;
  const track_slug = cleanSlug(datum?.track_slug || datum?.meeting?.track?.slug || datum?.replayURL?.track);
  const track_name = datum?.trackName || datum?.track_name || datum?.meeting?.track?.name;
  const thumbnail_url = datum?.thumbnailUrl || datum?.replay_video?.placeholder_image_url || (datum?.replay_videos?.length && datum?.replay_videos[0]?.placeholder_image_url);

  if (!thumbnail_url) return null;

  const { date, time } = prepareParams({
    time: start_time_scheduled,
    slug: track_slug,
  });

  const descriptionHeading = `${track_name || ""} ${formatRaceTime(start_time_scheduled) || ""} - ${datum?.title?.capitalize() || ""}` || "";
  const alt_text = `Video preview image for ${descriptionHeading}`;

  return (
    <RTouchableOpacity
      key={idx}
      style={{ display: "flex", flexDirection: "row" }}
      navScreen="IndividualReplay"
      navParams={{
        date: date,
        time: time,
        track: track_slug,
      }}
    >
      <ContentPreview
        loading={idx <= 2 ? "eager" : "lazy"}
        alt={alt_text}
        descriptionHeading={descriptionHeading}
        displayAs="card"
        thumbnailUrl={thumbnail_url}
        handleLayout={handleLayout}
        video
        style={
          isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem
        }
        onPress={
          Platform.OS === "web"
            ? () => {}
            : () =>
                handlePressReplay(
                  {
                    start_time_scheduled: start_time_scheduled,
                    track_slug: track_slug,
                  },
                  nav
                )
        }
      />
      <Gap />
    </RTouchableOpacity>
  );
});

// prettier-ignore
const renderSimpleStreamDemandVideo = memo(
  ({
    datum,
    handleLayout,
    idx,
    onPress,
    isXSmallOnly,
    nav,
  }: ContentScrollBarItem<VideoAsset>) => {
    let heading = "";

    const titleLower = datum?.title?.toLowerCase() || "";
    const isPredictor = titleLower.includes("predictor");
    const isVideoAnalysis = titleLower.includes("form");
    const isTipVideo = datum?.isTip;
    const isReplay = datum?.isReplay;
    const isLiveStream = datum?.isLiveStream;

    let fallbackImage = null;
    if (isPredictor) {
      heading = "Predictor";
      fallbackImage = DEFAULT_PREDICTOR_IMAGE;
    } else if (isVideoAnalysis) {
      heading = "Video Analysis";
      fallbackImage = DEFAULT_VIDEO_ANALYSIS_IMAGE;
    } else if (isTipVideo) {
      heading = "Tip";
      fallbackImage = DEFAULT_OFFER_IMAGE;
    } else if (isReplay) {
      fallbackImage = DEFAULT_REPLAY_IMAGE;
    } else if (isLiveStream) {
      heading = "Watch Live";
      fallbackImage = datum.liveStreamBackground || DEFAULT_RTV_LIVE_IMAGE;
    }

    const iconMap = {
      Predictor: "binocularsSquare",
      "Video Analysis": "binocularsSquare",
      Tip: "hat",
      "Watch Live": "logoSquare",
    };




    if(isReplay) {
      return (
        <View style={{ display: "flex", flexDirection: "row" }} key={idx}>
        <ContentPreview
          loading={idx <= 2 ? "eager" : "lazy"}
          descriptionHeading={heading || datum?.catchup_video?.title || datum?.description || datum?.title || ""}
          descriptionIcon={iconMap[heading] || null}
          displayAs="card"
          thumbnailUrl={datum?.catchup_video?.placeholder_image_url || datum.placeholder_image_url || fallbackImage || ""}
          handleLayout={handleLayout}
          video
          isLivePreview={isLiveStream}
          style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
          onPress={isLiveStream ? () => nav.navigate("Watch") : onPress}
        />
        <Gap />
      </View>
      )
    }

    return (
      <View style={{ display: "flex", flexDirection: "row" }} key={idx}>
        <ContentPreview
          loading={idx <= 2 ? "eager" : "lazy"}
          descriptionHeading={
            heading ||
            datum?.catchup_video?.title ||
            datum?.description ||
            datum?.title ||
            ""
          }
          descriptionIcon={iconMap[heading] || null}
          displayAs="card"
          thumbnailUrl={
            datum?.catchup_video?.placeholder_image_url ||
            datum.placeholder_image_url ||
            fallbackImage ||
            ""
          }
          handleLayout={handleLayout}
          video
          isLivePreview={isLiveStream}
          style={
            isXSmallOnly
              ? styles.smallScreenScrollBarItem
              : styles.scrollBarItem
          }
          onPress={onPress}
        />
        <Gap />
      </View>
    );
  }
);

// prettier-ignore
const renderTipVideo = memo(({ datum, handleLayout, idx, onPress, isXSmallOnly, nav }: ContentScrollBarItem<VideoAsset>) => {
  if (datum?.type === 'article') {
    return (
      <RTouchableOpacity
        navScreen="NewsPost"
        navParams={{ slug: cleanSlug(datum.slug) }}
        style={{ display: "flex", flexDirection: "row" }}
        key={idx}
      >
        <ContentPreview
          loading={idx <= 2 ? "eager" : "lazy"}
          thumbnailUrl={newsThumbnail(datum)}
          descriptionHeading={datum.headline}
          displayAs="card"
          handleLayout={handleLayout}
          style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
          onPress={Platform.OS === "web" ? () => {} : () => nav.navigate("NewsPost", { slug: cleanSlug(datum.slug) })}
        />
        <Gap />
      </RTouchableOpacity>
    )
  }

  return (
    <View style={{ display: "flex", flexDirection: "row" }} key={idx}>
      <ContentPreview
        loading={idx <= 2 ? "eager" : "lazy"}
        descriptionHeading={datum?.preview_video?.description || datum?.description || ""}
        descriptionIcon="hat"
        displayAs="card"
        thumbnailUrl={datum?.preview_video?.placeholder_image_url || datum?.placeholder_image_url || ""}
        handleLayout={handleLayout}
        video
        style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
        onPress={onPress}
      />
      <Gap />
    </View>
  );
});

// prettier-ignore
const renderOnDemand = memo(({ datum, handleLayout, idx, isXSmallOnly, nav }: ContentScrollBarItem<OnDemandVideo>) => {
  const categorySlug = cleanSlug(datum.categories ? datum.categories[0].slug : datum.categorySlug);

  return (
    <RTouchableOpacity
      style={{ display: "flex", flexDirection: "row" }}
      key={idx}
      navScreen="IndividualOnDemand"
      navParams={{ id: datum?.id, slug: categorySlug }}
    >
      <ContentPreview
        loading={idx <= 2 ? "eager" : "lazy"}
        descriptionHeading={datum.catchup_video?.title || datum.title || ""}
        displayAs="card"
        thumbnailUrl={datum.catchup_video?.placeholder_image_url || datum.placeholder_image_url || ""}
        handleLayout={handleLayout}
        video
        capitalize={false}
        style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
        onPress={Platform.OS === "web" ? () => {} : () => handlePressOnDemand({ id: datum.id, slug: categorySlug }, nav)}
      />
      <Gap />
    </RTouchableOpacity>
  )
});

// prettier-ignore
const renderUpcomingRace = memo(({ datum, handleLayout, idx }: ContentScrollBarItem<UpcomingRace>) => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", paddingTop: 15 }}
      key={idx}
    >
      <Race
        race={datum}
        description={["abandoned", "void"].includes(datum?.status?.state) ? "ABANDONED/VOID" : datum.title}
        RMG={datum.tv_channel == "RTV"}
        nextRace={idx == 0}
        handleLayout={handleLayout}
      />
      <Gap size="xsmall" />
    </View>
  );
});

// prettier-ignore
const renderMemberBenefit = memo(({ datum, handleLayout, idx, nav, isXSmallOnly }: ContentScrollBarItem<MemberBenefit>) => {
  const { screen } = nativeNavFromSlug(datum.path);
  if (!screen) return null;

  return (
      <RTouchableOpacity
        navScreen={screen}
        navParams={{}}
        style={{ display: "flex", flexDirection: "row" }}
      >
        <ContentPreview
          loading={idx <= 2 ? "eager" : "lazy"}
          descriptionHeading={datum.description}
          descriptionText={datum.description}
          overlayText={datum.title}
          displayAs="card"
          thumbnailUrl={datum?.image?.viewer_sources.length ? datum.image.viewer_sources[0].url: datum.image.placeholder_image_url}
          thumbnailText={datum.title}
          handleLayout={handleLayout}
          style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
          onPress={Platform.OS === "web" ? () => {} : () => nav.navigate(screen, {})}
          showRCorner={true}
        />
        <Gap />
      </RTouchableOpacity>
  );
});

// prettier-ignore
const renderNewsArticles = memo(({ datum, handleLayout, idx, nav, isXSmallOnly }: ContentScrollBarItem<Article>) => {
  return (
    <RTouchableOpacity
      navScreen="NewsPost"
      navParams={{ slug: cleanSlug(datum.slug) }}
      style={{ display: "flex", flexDirection: "row" }}
      key={idx}
    >
      <ContentPreview
        loading={idx <= 2 ? "eager" : "lazy"}
        thumbnailUrl={newsThumbnail(datum)}
        descriptionHeading={datum.headline}
        displayAs="card"
        handleLayout={handleLayout}
        style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
        onPress={Platform.OS === "web" ? () => {} : () => nav.navigate("NewsPost", { slug: cleanSlug(datum.slug) })}
      />
      <Gap />
    </RTouchableOpacity>
  );
});

// prettier-ignore
const renderNewsColumnists = memo(({ datum, handleLayout, idx, nav, isXSmallOnly }) => {
  if (!datum.articles?.length) return null;
  return (
    <RTouchableOpacity
      navScreen="IndividualColumnist"
      navParams={{ columnist_slug: cleanSlug(datum.slug) }}
      style={{ display: "flex", flexDirection: "row" }}
      key={idx}
    >
      <ContentPreview
        loading={idx <= 2 ? "eager" : "lazy"}
        thumbnailUrl={ datum.profile_picture?.placeholder_image_url || require("../../assets/default-columnists-image.jpg")}
        descriptionHeading={datum.name?.formatted}
        displayAs="card"
        handleLayout={handleLayout}
        style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
        onPress={Platform.OS === "web" ? () => {} : () => nav.navigate("IndividualColumnist", {columnist_slug: cleanSlug(datum.slug)})}
      />
      <Gap />
    </RTouchableOpacity>
  );
});

// prettier-ignore
const renderNewsColumnistArticles = memo(({ datum, handleLayout, idx, nav, isXSmallOnly }) => (
  <RTouchableOpacity
    navScreen="NewsPost"
    navParams={{
      slug: cleanSlug(datum.slug),
    }}
    style={{ display: "flex", flexDirection: "row" }}
    key={idx}
  >
    <ContentPreview
      loading={idx <= 2 ? "eager" : "lazy"}
      thumbnailUrl={newsThumbnail(datum)}
      descriptionHeading={datum.headline}
      displayAs="card"
      handleLayout={handleLayout}
      style={isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem}
      key={idx}
      capitalize={false}
      onPress={
        Platform.OS === "web" ? () => {} : () => nav.navigate("NewsPost", { slug: cleanSlug(datum.slug)})
      }
    />
      <Gap />
    </RTouchableOpacity>
));

// prettier-ignore
const renderFreeBet = memo(({ datum, handleLayout, idx, isXSmallOnly }: ContentScrollBarItem<FreeBet>) => {
  return (
    <View style={{ display: "flex", flexDirection: "row" }} key={idx}>
      <ContentPreview
        loading={idx <= 2 ? "eager" : "lazy"}
        data={datum}
        descriptionHeading={datum.title || datum.text}
        displayAs="free-bet"
        icon="infoPink"
        thumbnailUrl={
          datum?.hero_image?.placeholder_image_url || DEFAULT_OFFER_IMAGE
        }
        handleLayout={handleLayout}
        style={ isXSmallOnly ? styles.smallScreenScrollBarItem : styles.scrollBarItem }
        externalHref={datum.link}
        onPress={() => Linking.openURL(datum.link)}
        xSmallOnly={isXSmallOnly}
      />
      <Gap />
    </View>
  )
});

const methodMap = {
  Replays: {
    component: renderReplay,
    key: "races",
    transform: (x: any) => x,
  },
  SimpleStreamDemandVideo: {
    component: renderSimpleStreamDemandVideo,
    key: "meetings.completed_races.replay_videos",
    transform: (x: any) => x,
  },
  TipVideo: {
    component: renderTipVideo,
    key: "videos",
    transform: (x: any) => x,
  },
  OnDemand: {
    component: renderOnDemand,
    key: "videos",
    transform: (x: any) => x,
  },
  UpcomingRaces: {
    component: renderUpcomingRace,
    key: "races",
    transform: (x: any) => x,
    heightOverride: 115,
  },
  MembersBenefits: {
    component: renderMemberBenefit,
    key: "benefits",
    transform: (x: any) => x,
  },
  NewsArticles: {
    component: renderNewsArticles,
    key: "articles",
    transform: (x: any) => x,
  },
  NewsColumnists: {
    component: renderNewsColumnists,
    key: "columnists",
    transform: (x: any) => x,
  },
  NewsColumnistsArticles: {
    component: renderNewsColumnistArticles,
    key: "columnist.articles",
    transform: (x: any) => x,
  },
  FreeBets: {
    component: renderFreeBet,
    key: "offers",
    transform: (x: any) => x,
  },
};

type Props = {
  type: ContentTypes;
  fetchGet?: Promise<any>;
  dataOverride?: any[];
  contentLoadingOverride?: boolean;
  onPress?: (
    data: any,
    navigation?:
      | StackNavigationProp<RootStackParamList>
      | NavigationProp<ReactNavigation.RootParamList>,
    setGlobalPlayerSource?: Function,
    params?: any,
    method?: Function
  ) => void;
  onPressExtraParams?: any;
  onPressVideoFetchMethod?: Function;
  category?: string;
  isUsingDataOverride?: boolean;
};

// FetchGet should only be be omitted if dataOverride is passed.
// They should never both be passed or omitted.
export default function ContentScrollBar({
  type,
  fetchGet,
  dataOverride = [],
  contentLoadingOverride = false,
  onPress,
  onPressExtraParams,
  onPressVideoFetchMethod,
  category,
  isUsingDataOverride = false,
}: Props) {
  const [contentLoading, setContentLoading] = useState<boolean>(
    contentLoadingOverride ? contentLoadingOverride : true
  ); // keep as loading until
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState(null);
  const navigation = useNavigation();
  const scrollRef = React.useRef<FlatList>(null);
  const [, setArticlesForNavigation] = useAtom(articlesForNavigationAtom);
  const [, setGlobalPlayerSource] = useAtom(globalPlayerSourceAtom);
  const { isXSmallOnly } = useResponsive();

  useEffect(() => {
    if (isUsingDataOverride) {
      setData(dataOverride);
      setContentLoading(false);
    } else {
      fetchGet
        .then(async (res: any) => {
          setData(
            methodMap[type].transform(resolveValue(res, methodMap[type].key))
          );
        })
        .catch((err: Error) => setError(err.message))
        .finally(() => setContentLoading(false));
    }
  }, []);

  // show loading state if content is loading
  if (contentLoading || contentLoadingOverride) {
    if (type == "UpcomingRaces") {
      return <LoadingState name="UpcomingRacesLoader" />;
    } else {
      return <LoadingState name="ContentScrollBarLoader" />;
    }
  }

  // show error state if data fetch is complete and there is an error
  if (!!error) return <ErrorState style={{ padding: 50 }} message={error} />;

  if (!data?.length && !dataOverride?.length)
    return (
      <EmptyState
        style={{ paddingVertical: 60 }}
        message="nothing to display"
      />
    );

  // Destructure the component class from methodMap using the type key
  const SpecificComponent = methodMap[type].component;
  const heightOverride = methodMap[type].heightOverride;

  // Ensure SpecificComponent is a valid React component
  if (
    typeof SpecificComponent !== "function" &&
    typeof SpecificComponent !== "object"
  ) {
    console.error(
      "Expected a React component but got:",
      typeof SpecificComponent
    );
    return null; // or some fallback UI
  }

  return (
    <View style={{ marginBottom: 10 }}>
      <RFlatList
        scrollRef={scrollRef}
        keyExtractor={(datum, index) => `${methodMap[type]}-item-${index}`}
        elementDistance={itemWidth + variables.spacing.small}
        horizontal
        showsHorizontalScrollIndicator={false}
        childrenWidth={itemWidth * data?.length}
        data={data}
        heightOverride={heightOverride}
        renderItem={({ item: datum, index }) => {
          // Render the component directly with JSX, passing in props
          return (
            <SpecificComponent
              datum={datum}
              idx={index}
              nav={navigation}
              category={category}
              onPress={() =>
                onPress?.(
                  datum,
                  navigation,
                  setGlobalPlayerSource,
                  onPressExtraParams,
                  onPressVideoFetchMethod
                )
              }
              scrollRef={scrollRef}
              data={data}
              setArticles={setArticlesForNavigation}
              isXSmallOnly={isXSmallOnly}
            />
          );
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  scrollBarItem: {
    minWidth: itemWidth,
    maxWidth: itemWidth,
  },
  smallScreenScrollBarItem: {
    minWidth: smallScreenItemWidth,
    maxWidth: smallScreenItemWidth,
  },
});
