import { useAtom } from "jotai";
import { useEffect } from "react";
import { requestPurchase, useIAP } from "react-native-iap";
import { useToast } from "react-native-toast-notifications";
import { openAuthModalAtom, userAtom } from "../atoms";
import {
  createAppleSubscription,
  CreateAppleSubscriptionSuccess,
  CreateAppleSubscriptionFailure,
  checkAppleSubscription,
} from "../client/apple/applePaySubscription";
import { APPLE_PAY_AVAILABLE } from "../utils/flags";
// types

export const useApplePay = () => {
  if (!APPLE_PAY_AVAILABLE) return;

  const SUB_ID = "DayPass_24";
  const PRODUCT_MAP = {
    DayPass_24: {
      name: "Day Pass",
      description: "Watch Racing UK for 24 hours",
    },
  };

  const [_, setIsAuthModalOpen] = useAtom(openAuthModalAtom);
  const [user] = useAtom(userAtom);
  const toast = useToast();

  const {
    connected,
    products,
    subscriptions,
    purchaseHistory,
    availablePurchases,
    currentPurchaseError,
    getProducts,
    getSubscriptions,
    getAvailablePurchases,
    getPurchaseHistory,
  } = useIAP();

  useEffect(() => {
    // ... listen to currentPurchaseError, to check if any error happened
    if (currentPurchaseError == undefined) return;
    console.log("[IAP] currentPurchaseError:: ", currentPurchaseError);
    toast.show("Purchase cancelled - you've not been charged", {
      type: "danger",
      placement: "bottom",
      duration: 5000,
      animationType: "slide-in",
    });
  }, [currentPurchaseError]);

  // sku == productId (e.g "DayPass_24")
  const handlePurchase = async (sku: string) => {
    await getEverything();

    console.log("[IAP] @@@@@@@@@handlePurchase pressed with sku: ", sku);

    const shouldProceed = await purchaseShouldProceed(sku);

    if (!shouldProceed) return;

    console.log("[IAP] @@@@@@@@@Purchase can proceed...");
    await requestPurchase({ sku })
      .then((result) => {
        console.log(
          "[IAP] @@@@@@@@@got result from requestPurchase...: ",
          result
        );
        // "appAccountToken": "", "originalTransactionDateIOS": 1718015677000, "originalTransactionIdentifierIOS": 2000000623567624, "productId": "DayPass_24", "purchaseToken": "", "quantityIOS": 1, "transactionDate": 1718015677000, "transactionId": "2000000623567624", "transactionReasonIOS": "PURCHASE", "transactionReceipt": "", "verificationResultIOS": "ey.................."
      })
      .then(async () => {
        console.log("[IAP] @@@@@@@@@creating apple sub with user:: ", user);
        const res:
          | CreateAppleSubscriptionSuccess
          | CreateAppleSubscriptionFailure = await createAppleSubscription(
          user
        );

        if (res.access_device) {
          return;
        } else {
          throw new Error(
            `Failed to create apple subscription at API level: ${JSON.stringify(
              res
            )}`
          );
        }
      })
      .then(async () => {
        console.log("[IAP] @@@@@@@@@set ios_paid");

        await checkAppleSubscription().then((res) => {
          console.log("[IAP] RES after ios paid... ", res);

          if (res.error) {
            console.log("[IAP] checkAppleSubscription error:: ", res.error);
            setIsAuthModalOpen("login");
          }

          if (res.access_device) {
            console.log(
              "[IAP] checkAppleSubscription success:: ",
              res.access_device
            );
            setIsAuthModalOpen("ios_paid");
          }
        });
      })
      .catch((error) => {
        console.log(
          "[IAP] @@@@@@@@@got an error with request purchase::",
          error
        );
      });

    console.log("[IAP] @@@@@@@@@finished handlePurchase");
  };

  const purchaseShouldProceed = async (sku: string) => {
    console.log("[IAP] purchaseShouldProceed called with sku: ", sku);
    if (!connected) {
      console.log("[IAP] not connected");
      return false;
    }

    const existingAppleSub = await checkAppleSubscription();
    const backendHasPurchaseToday = existingAppleSub.access_device;
    console.log("[IAP] purchaseHistory.length: ", purchaseHistory.length);

    if (backendHasPurchaseToday) {
      console.log("[IAP] You've already purchased the Day Pass today!");
      toast.show("You've already purchased the Day Pass today!", {
        type: "danger",
        placement: "bottom",
        duration: 5000,
        animationType: "slide-in",
      });

      setIsAuthModalOpen(false);
      return false;
    }
    return true;
  };

  const getEverything = () => {
    console.log("[IAP] connected? ", connected);
    getProducts({ skus: [SUB_ID] }).then(() =>
      console.log("[IAP] getProducts: ", products)
    );

    getSubscriptions({ skus: [SUB_ID] }).then(() =>
      console.log("[IAP] getSubscriptions: ", subscriptions)
    );

    getAvailablePurchases().then(() =>
      console.log("[IAP] getAvailablePurchases: ", availablePurchases)
    );

    getPurchaseHistory().then(() =>
      console.log(
        "[IAP] getPurchaseHistory.... (commented out): , purchaseHistory"
      )
    );
  };

  return {
    handlePurchase,
    getEverything,
    products,
    purchaseHistory,
    PRODUCT_MAP,
  };
};
