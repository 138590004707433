import React, { useLayoutEffect, useState } from "react";
import RButton from "../../Buttons/RButton";
import { Platform, Pressable, StyleSheet, View } from "react-native";
import Icon from "../../Icon";
import { useResponsive } from "../../../hooks/useResponsive";
import variables from "../../../styles/variables";
import { useNavigation } from "@react-navigation/native";
import { useAtom } from "jotai";
import {
  openAuthModalAtom,
  unreadNotificationCountAtom,
  userAtom,
} from "../../../atoms";
import { StackNavigationProp } from "@react-navigation/stack";
import { getUserState } from "../../../utils/auth";
import { getDataFromStorage } from "../../Screens/Account/components/Utils";
import { RootStackParamList } from "../../../types/route";
import LiveButtonLink from "../../Buttons/LiveButtonLink";
import Gap from "../../Gap";

// rendeers the diffferent buttons on web and native based on the users subscrition status
const HeaderButtons = () => {
  if (Platform.OS !== "web") return;

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const [, setIsAuthModalOpen] = useAtom(openAuthModalAtom);
  const { isSmallAndDown, isMediumAndDown } = useResponsive();
  const [user] = useAtom(userAtom);
  const [unreadCount] = useAtom(unreadNotificationCountAtom);

  let userState = getUserState(user);

  // check if we've got a user in local storage
  useLayoutEffect(() => {
    getDataFromStorage().then((userData) => {
      if (userData) userState = getUserState(userData);
    });
  }, [user]);

  const buttonsToRender = (userState: string) => {
    switch (userState) {
      case "free_account":
        return (
          <>
            <View>
              <Pressable
                style={styles.button}
                onPress={() => navigation?.navigate("Account")}
              >
                <Icon
                  name="profile"
                  size="xlarge"
                  fill="palette.rtv.white"
                  notifications={unreadCount}
                />
              </Pressable>
            </View>
            <RButton
              onPress={() => setIsAuthModalOpen("upgrade")}
              size="medium"
              style={styles.button}
              title="Packages"
              type="standard"
              customTextStyle={{
                fontWeight: "700",
                fontSize: isSmallAndDown ? 14 : 16,
              }}
            />
          </>
        );
      case "paid_account":
        return (
          <>
            <View>
              <Pressable
                style={styles.button}
                onPress={() => navigation?.navigate("Account")}
              >
                <Icon
                  name="profile"
                  size={isMediumAndDown ? "larger" : "xlarge"}
                  fill="palette.rtv.white"
                  notifications={unreadCount}
                />
              </Pressable>
            </View>
            <Gap size="xxsmall" />
            <LiveButtonLink />
          </>
        );

      default: // anonymous
        return (
          <View
            style={[
              !isMediumAndDown ? { marginLeft: 5 } : {},
              styles.webButtonsContainer,
            ]}
          >
            <RButton
              size={isSmallAndDown ? "small" : "medium"}
              onPress={() => setIsAuthModalOpen("login")}
              style={styles.transparentButton}
              title="Login"
              type={"standard"}
              customTextStyle={{
                fontWeight: "700",
                fontSize: isSmallAndDown ? 14 : 16,
              }}
            />
            <RButton
              onPress={() => setIsAuthModalOpen("join")}
              style={styles.button}
              size={isSmallAndDown ? "small" : "medium"}
              title="Join"
              type={"standard"}
              customTextStyle={{
                fontWeight: "700",
                fontSize: isSmallAndDown ? 14 : 16,
              }}
            />
          </View>
        );
    }
  };
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        minWidth: 130,
      }}
    >
      {buttonsToRender(userState)}
    </View>
  );
};

const { spacing } = variables;

const styles = StyleSheet.create({
  button: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: spacing?.xxsmall,
    cursor: "pointer",
  },
  buttonRound: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: spacing?.xxsmall,
    borderRadius: 20,
    height: 40,
  },
  transparentButton: {
    // marginLeft: spacing?.xsmall,
    backgroundColor: "transparent",
  },
  webButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  livePlayContentContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  liveText: {
    textTransform: "uppercase",
  },
});

export default HeaderButtons;
